<template lang='pug'>
div
  v-container(fluid, justify-center, align-center)
    v-tabs(v-model="activeTab", touchless)
      v-tab(v-for="tab in tabs", :key="tab.key")
        span {{ tab.label }}
      v-tabs-items(v-model="activeTab")
        v-tab-item(v-for="tab in tabs", :key="tab.key")
          DataFilter.ma-1.pa-2(
            :filterProps="filters",
            :items="activeDateset",
            :paginate="false"
          )
            template(v-slot:actions)
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mr-2.mt-1(
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="getData"
                  )
                    v-icon mdi mdi-refresh
                span Atualizar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mt-1(
                    v-if="isGerente",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="newCadastroDialog"
                  )
                    v-icon mdi mdi-plus
                span Adicionar

              v-divider(vertical, v-if="isGerente")

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.mr-5.mt-1(
                    v-if="isGerente",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="dialogEnviar = true"
                  )
                    v-icon mdi mdi-cube-send
                span Forçar Envio

            template(v-slot:default="{ items }")
              v-data-table.reinfTable(
                :headers="activeHeaders",
                :items="items",
                item-key="id",
                dense,
                :footer-props="footerProps",
                :loading="loading"
              )
                template(v-slot:item.actions="{ item }")
                  td.text-center.pr-2.pl-0
                    v-tooltip(bottom, v-if="isGerente")
                      template(v-slot:activator="{ on }")
                        v-btn(
                          v-on="on",
                          color="primary",
                          fab,
                          small,
                          icon,
                          @click="editCadastroDialog(item)"
                        )
                          v-icon mdi mdi-pencil-circle-outline
                      span Editar

  v-dialog(v-model="dialogEnviar", max-width="500")
    v-card(style="width: 500px")
      v-card-title
        h3 Forçar envio de dados
      v-card-text.mt-4
        div(style="font-size: 14px") Selecione um cliente para encaminhar o comando de atualização das tabelas do ADAPAR. Este comando atualiza todas as tabelas desta funcionalidade.

        v-autocomplete.mt-4(
          v-model="clienteSelected",
          :items="datasetClientes",
          :disabled="datasetClientes.length === 0",
          :loading="datasetClientes.length === 0",
          placeholder="Digite ou selecione um cliente",
          return-object,
          item-text="nome",
          item-value="idcliente",
          label="Cliente",
          dense,
          outlined,
          hide-details
        )

      v-card-actions.pa-2.pt-0.pr-1.pb-4
        v-spacer
        v-btn(color="primary", outlined, @click="dialogEnviar = false")
          span Cancelar
        v-btn(
          color="primary",
          :disabled="dialogEnviarLoadingSend",
          outlined,
          @click="sendCliente()"
        )
          span Enviar

  v-dialog(v-model="dialogCadastrarEditar", max-width="850", persistent)
    v-card(style="width: 850px")
      v-card-title
        h3 {{ tabs[activeTab].label }} - {{ dialogCadastrarEditarType }}
      v-card-text.pt-10(:style="cardTextStyle")
        v-form(ref="form")
          v-row
            v-col.ma-0.pa-2.py-1.text-right(
              :cols="12",
              :md="header.cols",
              v-for="header in filteredHeaders",
              :key="header.value"
            )

              v-text-field(
                v-model="itemManipulate[header.value]",
                :label="header.text",
                outlined,
                :disabled="['id', 'empresa'].includes(header.value)",
                dense
              )

      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeCadastroDialog")
          span Cancelar
        v-btn(color="success", outlined, @click="saveCadastro")
          span Salvar

  </template>
  
  <script>
  import { mapMutations } from "vuex";
  import csapi from "@/api/csapi";
  import { clientes as api } from "@/api";
  
  export default {
    data: () => ({
      activeDateset: [{}],
      loading: false,
      activeTab: 0,
      activeHeaders: [],
      isGerente: false,
      dialogEnviar: false,
      dialogCadastrarEditar: false,
      dialogCadastrarEditarType: "Inclusão",
      itemManipulate: {},
      clienteSelected: null,
      datasetClientes: [],
      dialogEnviarLoadingSend: false,
      tabs: [
        { key: 0, label: "Fitos" },
        { key: 1, label: "Culturas" },
        { key: 2, label: "Pragas" },
        { key: 3, label: "Unidade de Dosagem" },
      ],
      footerProps: {
        itemsPerPageOptions: [50, 100, 300, 500, -1],
        itemsPerPageAllText: "Todos",
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: "Itens por página",
      },
      filters: {
        includeSearch: true,
        items: [],
        searchText: "",
        useSearchHash: false,
      },


    }),
    methods: {
      ...mapMutations("app", ["setTitle", "isControl"]),
      ...mapMutations("loader", ["showLoader", "hideLoader"]),

      async fetchData(url) {
        try {
          this.activeDateset = [{}];
          this.loading = true;

          const response = await csapi.get(url);
          response.data.forEach((item) => {
            item.criadoem = new Date(item.criadoem).toLocaleString();
            item.atualizadoem = new Date(item.atualizadoem).toLocaleString();
          });
          this.activeDateset = response.data;

        } catch (error) {
          console.error(error);
          this.$toast.error(`Erro ao requisitar dados: ${error.message}`);
        } finally {
          this.loading = false;
        }
      },

      getData() {
        const actions = {
          0: this.getHeadersKey0, // Fitos
          1: this.getHeadersKey1, // Culturas
          2: this.getHeadersKey2, // Pragas
          3: this.getHeadersKey3, // Unidade de Dosagem
        };
        const action = actions[this.activeTab] || this.getHeadersKey0;
        action.call(this);
      },

      editCadastroDialog(item) {
        this.dialogCadastrarEditarType = "Alteração";
        this.dialogCadastrarEditar = true;

        this.itemManipulate = JSON.parse(JSON.stringify(item));
      },
        
      getLink(tab) {
        const links = {
          0: "/v1/databridge/adapar/fitos",
          1: "/v1/databridge/adapar/culturas",
          2: "/v1/databridge/adapar/pragas",
          3: "/v1/databridge/adapar/unidadedosagem",
        };
        return links[tab] || "/v1/databridge/adapar";
      },

      getHeadersKey0() {
        this.activeHeaders = [
          { text: "ID", value: "id", width: "70", cols: 1 },
          { text: "Nome", value: "nome", width: "280", cols: 5 },
          { text: "Código Externo", value: "codigoexterno", width: "150", cols: 3 },
          { text: "Criado em", value: "criadoem", width: "280", cols: 4 },
          { text: "Atualizados em", value: "atualizadoem", width: "280", cols: 4 },
        ];

        if (this.isGerente) {
          this.createActionColumn();
        }

        this.fetchData(this.getLink(this.activeTab));
      },  
      getHeadersKey1() {
        this.activeHeaders = [
          { text: "ID", value: "id", width: "70", cols: 1 },
          { text: "Nome", value: "nomecomum", width: "280", cols: 5 },
          { text: "Código Externo", value: "codigoexterno", width: "150", cols: 3 },
          { text: "Criado em", value: "criadoem", width: "280", cols: 4 },
          { text: "Atualizados em", value: "atualizadoem", width: "280", cols: 4 },
        ];

        if (this.isGerente) {
          this.createActionColumn();
        }

        this.fetchData(this.getLink(this.activeTab));
      }, 
      getHeadersKey2() {
        this.activeHeaders = [
          { text: "ID", value: "id", width: "70", cols: 1 },
          { text: "Nome", value: "nomecomum", width: "280", cols: 5 },
          { text: "Código Externo", value: "codigoexterno", width: "150", cols: 3 },
          { text: "Criado em", value: "criadoem", width: "280", cols: 4 },
          { text: "Atualizados em", value: "atualizadoem", width: "280", cols: 4 },
        ];

        if (this.isGerente) {
          this.createActionColumn();
        }

        this.fetchData(this.getLink(this.activeTab));
      },  
      getHeadersKey3() {
        this.activeHeaders = [
          { text: "ID", value: "id", width: "70", cols: 1 },
          { text: "Nome", value: "nomecomum", width: "280", cols: 5 },
          { text: "Código Externo", value: "codigoexterno", width: "150", cols: 3 },
          { text: "Criado em", value: "criadoem", width: "280", cols: 4 },
          { text: "Atualizados em", value: "atualizadoem", width: "280", cols: 4 },
        ];

        if (this.isGerente) {
          this.createActionColumn();
        }

        this.fetchData(this.getLink(this.activeTab));
      },

      createActionColumn() {
        this.activeHeaders.push({
          text: "Ações",
          value: "actions",
          width: "100",
          align: "center",
          sortable: false,
          cols: 12,
        });
      },

      async sendCliente() {
        this.dialogEnviarLoadingSend = true;

        // Verificar se o cliente foi selecionado
        if (!this.clienteSelected) {
          let msg = "Selecione um cliente para enviar o comando";

          this.$toast.error(msg);
          this.dialogEnviarLoadingSend = false;
          return;
        }

        // Verificar se o cliente está na versão correta para enviar
        //let cversio = this.clienteSelected.versao;
        // if (this.compareVersions(cversio, "2025.2.1.4") <= 0) {
        //   let msg = "Server do cliente está em uma versão antiga";
        //   msg += " e não suporta essa funcionalidade";

        //   this.$toast.error(msg);
        //   this.dialogEnviarLoadingSend = false;
        //   return;
        // }

        try {
          const link = `/v1/databridge/integrate`;
          const data = {
            hash: [this.clienteSelected.hash],
            processo: "adapar",
          };

          const response = await csapi.post(link, data);

          if (response.status.toString().startsWith("2")) {
            this.$toast.success("Encaminhado com sucesso");
            this.clienteSelected = null;
            this.dialogEnviar = false;
          }
        } catch (error) {
          let msg = error.response.data.message;
          if (error.response.data.errors) {
            msg = error.response.data.errors[0].message;
          }
          this.$toast.error("Erro ao encaminhar registro. msg server: " + msg);
        }

        this.dialogEnviarLoadingSend = false;
      },

      newCadastroDialog() {
        this.dialogCadastrarEditarType = "Inclusão";
        this.dialogCadastrarEditar = true;

        this.itemManipulate = this.activeHeaders.reduce((acc, header) => {
          if (!["criadoem", "atualizadoem", "actions"].includes(header.value)) {
            acc[header.value] = null;
          }
          return acc;
        }, {});
      },

      adminRole() {
        const roles = JSON.parse(
          JSON.stringify(this.$store.state.auth.user.roles)
        );
        const adminRole = [];

        roles.forEach((role) => {
          if (role.name.toUpperCase() === "GERENTE") {
            adminRole.push(role);
          }
        });

        this.isGerente = adminRole.length > 0;
      },

      async saveCadastro() {
        let link = this.getLink(this.activeTab);

        try {
          let data = JSON.parse(JSON.stringify(this.itemManipulate));

          // Verificar os campos que estão vazios e transformar em null
          for (let key in data) {
            if (typeof data[key] === "string") {
              data[key] = data[key].trim();
            }

            if (data[key] === "") {
              data[key] = null;
            }
          }

          delete data.criadoem;
          delete data.atualizadoem;
          const id = data.id;
          if (data.id) {
            delete data.id;
          }

          console.log("data", data, link + "/" + id);

          // Verificar se é inclusão ou alteração e chamar a API
          let res =this.dialogCadastrarEditarType === "Inclusão"
            ? await csapi.post(link, data)
            : await csapi.patch(`${link}/${id}`, data);
          

          // Verificar o status da resposta e exibir mensagem
          if (res.status.toString().startsWith("2")) {
            const msg =
              this.dialogCadastrarEditarType === "Inclusão"
                ? "incluído"
                : "alterado";
            this.$toast.success("Registro ADAPAR " + msg + " com sucesso");

            this.dialogCadastrarEditar = false;
            this.dialogCadastrarEditarType = "Inclusão";
            this.itemManipulate = {};

            setTimeout(() => this.getData(), 1000);

          } else {
            this.$toast.error("Erro ao salvar registro");
          }
        } catch (error) {
          try {
            let message = error.response.data.errors[0];
            console.error("message", message);
            this.$toast.error(
              `Validação de gravação via API: Campo: \n${message.path[0]} - ${message.message}`
            );
          } catch (e) {
            let message = error.response.data.message;
            this.$toast.error(`Validação de gravação via API: \n${message}`);
          }
        }
      },

      async getDataClientes() {
        api.refreshAuthProd(this.$store.state.auth);

        try {
          this.datasetClientes = await api.getAll();
          this.datasetClientes.sort((a, b) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });
        } catch (e) {
          this.$toast.error("Ocorreu algum problema ao buscar as informações");
        }
      },

      closeCadastroDialog() {
        this.dialogCadastrarEditar = false;
        this.itemManipulate = {};

        this.dialogCadastrarEditarType = "Inclusão";
      },
    },
  
    mounted() {
      // Cabeçalho
      this.isControl(true);
      this.setTitle("ADAPAR");

      this.adminRole();

      this.getData()
      this.getDataClientes();
    },
  
    computed: {

      filteredHeaders() {
        const keys = ["id", "criadoem", "atualizadoem", "actions"];
        return this.activeHeaders.filter(
          (header) => !keys.includes(header.value)
        );
      },
      cardTextStyle() {
        return {
          maxHeight: "calc(100vh - 300px)",
          overflowY: "auto",
        };
      },
    },
  
    watch: {
      activeTab() {
        this.getData();
        this.filters.searchText = "";
      },
    },
  };
  </script>
  
  <style>
  .reinfTable .v-data-table__wrapper {
    height: calc(100vh - 290px);
    overflow-y: auto;
  }
  </style>